exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-content-en-js": () => import("./../../../src/pages/content.en.js" /* webpackChunkName: "component---src-pages-content-en-js" */),
  "component---src-pages-content-js": () => import("./../../../src/pages/content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-cookie-policy-en-js": () => import("./../../../src/pages/cookie-policy.en.js" /* webpackChunkName: "component---src-pages-cookie-policy-en-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-demo-en-js": () => import("./../../../src/pages/demo.en.js" /* webpackChunkName: "component---src-pages-demo-en-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-en-js": () => import("./../../../src/pages/marketing.en.js" /* webpackChunkName: "component---src-pages-marketing-en-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-releases-en-js": () => import("./../../../src/pages/releases.en.js" /* webpackChunkName: "component---src-pages-releases-en-js" */),
  "component---src-pages-releases-js": () => import("./../../../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */),
  "component---src-pages-technology-en-js": () => import("./../../../src/pages/technology.en.js" /* webpackChunkName: "component---src-pages-technology-en-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-upcoming-en-js": () => import("./../../../src/pages/upcoming.en.js" /* webpackChunkName: "component---src-pages-upcoming-en-js" */),
  "component---src-pages-upcoming-js": () => import("./../../../src/pages/upcoming.js" /* webpackChunkName: "component---src-pages-upcoming-js" */)
}

