import React, { useState, createContext, useContext, useCallback } from "react";

const initialState = {
  cookiesAcceptance: null
};

const AppContext = createContext({});

const AppProvider = (props) => {
  const { children } = props;
  const value = useAppProvider();

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useApp = () => {
  return useContext(AppContext);
};

const useAppProvider = () => {
  const [state, setState] = useState(initialState);

  const setCookiesAcceptance = useCallback((value) => {
    setState((state) => ({ ...state, cookiesAcceptance: value }));
  }, []);

  return { ...state, setCookiesAcceptance };
};

export { AppProvider, useApp };
