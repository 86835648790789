import React, { Suspense } from "react";
import { StyleProvider, AppProvider } from "@contexts";

export const wrapRootElement = ({ element }) => (
  <StyleProvider>
    <AppProvider>
      <Suspense fallback={null}>{element}</Suspense>
    </AppProvider>
  </StyleProvider>
);
